@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

/* Apply Rubik Font Globally */
body {
  font-family: 'Rubik', sans-serif;
  @apply font-sans; /* Tailwind's default sans family */
  overflow-y: hidden;
}


/* Apply globally to all scrollable elements */
html,
body,
* {
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: linear-gradient(to bottom, #4caf50, #81c784) #e0e0e0;
  /* Thumb | Track */
  font-family: 'Rubik', sans-serif;
}

/* For WebKit browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
  height: 12px;
  /* Height for horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #4caf50, #81c784);
  /* Gradient thumb */
  border-radius: 10px;
  /* Rounded edges */
  border: 3px solid #e0e0e0;
  /* Adds spacing around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, #388e3c, #66bb6a);
  /* Hover effect */
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
  /* Light track background */
  border-radius: 10px;
  /* Rounded edges for track */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
}


.App {
  text-align: center;
}

.mb-5 {
  margin-bottom: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-layout-header {
  height: unset !important;
}

.header-menu {
  max-width: 700px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.demo-logo {
  max-width: 254px;
  height: 100%;
  border-radius: 6px;
  margin-inline-end: 24px;
  display: flex;
  align-items: center;
}

.demo-logo img {
  height: 62px;
  padding: 3px
}

.shadow {
  box-shadow: 1px -2px 0px 0 rgb(0 0 0 / 0%), 0px 1px 8px 0 rgb(0 0 0 / 17%) !important
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* .anticon svg {
  display: none;
} */

.anticon-left {
  display: none;
}

.DisplayCard {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 9px;
}

.custom-shadow {
  border-radius: 9px;
  -webkit-box-shadow: 2px 4px 9px -6px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 2px 4px 9px -6px rgba(0, 0, 0, 0.6);
  box-shadow: 2px 4px 9px -6px rgba(0, 0, 0, 0.6);
}
.ant-steps-item-content{
  flex-grow: 1;
  /* width: ; */
}


@media (max-width: 1040px) {
  .header-menu {
    max-width: 500px;
  }
}