.header-menu .ant-menu-item {
    text-align: left; /* Align text to start */
  }
  
  .header-menu .ant-menu-root > .ant-menu-item-selected, .header-menu .ant-menu-root li.ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title {
    background-color: #AC3CE1 !important; /* Active tab background color */
    color: #fff !important; /* Active tab text color */
  }
  
  .header-menu .ant-menu-item:hover,  .header-menu li.ant-menu-submenu .ant-menu-submenu-title:hover {
    background-color: #AC3CE1 !important; /* Hover background color */
  }
  .header-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
  }
  .header-menu .ant-menu-item,  .header-menu li.ant-menu-submenu{
    max-width: 280px;
  }
  .header-menu li.ant-menu-submenu.ant-menu-submenu-open .ant-menu-sub .ant-menu-item:hover{
    display: flex;
    height: 40px;
    padding: 12px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05) !important;
    border-left: 5px solid #ffffff;
  }