@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

/* Apply Rubik Font Globally */
body {
  font-family: "Rubik", sans-serif;
  @apply font-sans;
  /* Tailwind's default sans family */
}

:root {
  --primary-color: #ac3ce1;
  --primary-light: #f7ecfc;
  --primary-red: #ec3636;
  --primary-orange: #f69a0e;
  --black-body: #0e0e0e;
  --secondary-shade: #828282;
  --primary-bg: #f8f8f9;
  --font-family: "Rubik", sans-serif;
  --white: #ffffff;
  --black: #000000;
}

/* Text Colors */
.text-primary {
  color: var(--primary-color);
}

.text-primary-light {
  color: var(--primary-light);
}

.text-primary-red {
  color: var(--primary-red);
}

.text-primary-orange {
  color: var(--primary-orange);
}

.text-black-body {
  color: var(--black-body);
}

.text-secondary-shade {
  color: var(--secondary-shade);
}

/* Background Colors */
.bg-primary {
  background-color: var(--primary-color);
}

.bg-primary-light {
  background-color: var(--primary-light);
}

.bg-primary-red {
  background-color: var(--primary-red);
}

.bg-primary-orange {
  background-color: var(--primary-orange);
}

.bg-black-body {
  background-color: var(--black-body);
}

.bg-secondary-shade {
  background-color: var(--secondary-shade);
}

.bg-primary-bg {
  background-color: var(--primary-bg);
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.common-header {
  padding: 10px;
}

.common-header :is(h1, h2, h3, h4, h5, h6) {
  margin-bottom: 0;
}

.recharge-page,
.get-pan-details,
.panlist-dash {
  padding: 20px;
}

/*Table Utility CSS*/
thead.ant-table-thead th.ant-table-cell {
  color: var(--secondary-shade);
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
}

.table-card-wrapper .ant-table-title {
  border-bottom: 1px solid #c9c9c9;
  padding: 10px 10px !important;
}

tbody.ant-table-tbody td.ant-table-cell {
  color: var(--black-body);
  font-family: var(--font-family);
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.ant-checkbox .ant-checkbox-inner {
  border: 1px solid var(--black-body) !important;
}

.ant-table-pagination {
  justify-content: center !important;
}

.header-menu .ant-menu-item.ant-menu-item-selected svg path,
.header-menu .ant-menu-item:hover svg path,
.header-menu li.ant-menu-submenu:hover svg path,
.header-menu li.ant-menu-submenu.ant-menu-submenu-open svg path {
  stroke: var(--white) !important;
}

.panlist-dash {
  overflow-y: auto;
}

.header-menu .ant-menu-item,
.header-menu .ant-menu-submenu-title {
  padding-left: 15px !important;
}

.main-sidebar .ant-menu-dark,
.main-sidebar .ant-menu-sub.ant-menu-inline {
  background: #0e0e0e !important;
}

.main-sidebar .ant-menu-sub.ant-menu-inline {
  padding-left: 15px;
}

.panlist-dash form label {
  color: #0e0e0e;
  font-family: var(--font-family);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
}

.panlist-dash form label .ant-checkbox {
  align-self: flex-start !important;
  top: 5px;
}

button.ant-btn.ant-btn-primary,
button.ant-btn.ant-btn-default {
 display: flex;
    padding: 7px 15px;
    align-items: center;
    gap: 17px;
    border-radius: 8px;
    font-family: var(--font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.4px;
    text-transform: capitalize;
    height: 38px;
}


button.ant-btn.ant-btn-primary {
  color: var(--white);
  background: #AC3CE1 !important;
}

button.ant-btn.ant-btn-default {
  border: 1px solid #828282;
  color: #828282 !important;
}

.ant-divider {
  border-block-start: 1px solid #DADADA !important;
}

.header-menu .ant-menu-item.ant-menu-item-selected svg circle,
.header-menu .ant-menu-item:hover svg circle,
.header-menu li.ant-menu-submenu:hover svg circle,
.header-menu li.ant-menu-submenu.ant-menu-submenu-open svg circle,
.header-menu .ant-menu-item.ant-menu-item-selected svg ellipse,
.header-menu .ant-menu-item:hover svg ellipse,
.header-menu li.ant-menu-submenu:hover svg ellipse,
.header-menu li.ant-menu-submenu.ant-menu-submenu-open svg ellipse {
  stroke: var(--white) !important;
}

.verification-details-wrapper .verified-details {
  border-bottom: 1px solid #E7E7E7;
}

.credit-score-indicator svg.speedometer {
  height: 200px !important;
}

.card-score {
  border: 1px solid rgba(14, 14, 14, 0.10);
  background: var(--white);
}
.card-score .ant-descriptions-item-container .ant-descriptions-item-label {
  color: #0E0E0E;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.4px;
  flex: 1;
}

.ant-modal-footer{
  display: flex !important;
}
